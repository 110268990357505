import { PlusOutlined } from "@ant-design/icons";
import { Drawer, Image, Skeleton, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import Parser from "html-react-parser";
import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import { FaFacebookF, FaRegCopy, FaWhatsapp } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import { useHistory, useParams } from "react-router-dom";
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import ReactStars from "react-stars";
import "swiper/css";
import "swiper/css/pagination";
import Breadcrumbs from "../../BreadCrumbs/BreadCrumbs";
import { store } from "../../Helper/Context/Store";
import GetSeoData from "../../Helper/GetSeoData";
import PostApiCall from "../../Helper/PostApi";
import AddToCart from "../AddToCart/AddToCart";
import AddToCartQty from "../AddToCart/AddToCartQty";
import InnerBanner from "../InnerBanner/InnerBanner";
import LoginModal from "../Login/LoginModal";
import "./ProductDetail.css";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const Products = [
  {
    addbtnstyle: "flower-add-btn",
    productname: "Flower Power",
  },
  {
    addbtnstyle: "orange-add-btn",
    productname: "Orange Blossom",
  },
  {
    addbtnstyle: "aqua-add-btn",
    productname: "Aqua Blue",
  },
  {
    addbtnstyle: "timber-add-btn",
    productname: "Timber Gold",
  },
];

export default function ProductDetail() {
  const params = useParams();
  let history = useHistory();
  const [apiData, setApiData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedVariantImg, setSelectedVariantImg] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState([]);
  const [variantImage, setVariantImage] = useState(null);
  const [soldBy, setSoldBy] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [variantName, setVariantName] = useState(null);
  const [itemName, setItemName] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [imgFile, setImgFile] = useState([]);
  const [mrp, setMRP] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [verticalName, setVerticalName] = useState("");
  const [verticalId, setVerticalId] = useState(null);
  const [catId, setCatId] = useState(null);
  const [inStock, setInStock] = useState("");
  const [countryOfOrigin, setCountryOfOrigin] = useState(null);
  const [shortDescription, setShortDescription] = useState(null);
  const [headline, setHeadline] = useState("");
  const [reviewDescription, setReviewDescription] = useState("");
  const [longDescription, setLongDescription] = useState(null);
  const { cartItems, storeStatus } = useContext(store);
  const [totalRatings, setTotalRatings] = useState(null);
  const [isInCart, setIsInCart] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [productReview, setProductReview] = useState("");
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [reviewData, setReviewData] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  // const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [urlData, setUrlData] = useState([]);
  const [avgRatings, setAvgRatings] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalSide, setModalSide] = useState("Log");
  const [productsbtncolor, setProductsBtnColor] = useState("");
  const [itemRoute, setItemRoute] = useState(null)

  useEffect(() => {
    const findStyleNameFromURL = () => {
      const url = params.productname.replace(
        /\s/g,
        "-"
      ).replace('/', '');
      let matchedStyle = "";
      Products?.map((data) => {
        if (url.includes(data.productname.toLowerCase().replace(
          /\s/g,
          "-"
        ).replace('/', ''))) {
          matchedStyle = data.addbtnstyle;
          return;
        }
      });
      setProductsBtnColor(matchedStyle);
    };

    findStyleNameFromURL();
  }, [params.productname]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNumber(generateRandomNumber);
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);
  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 41) + 10;
  };
  const [number, setNumber] = useState(generateRandomNumber);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = async ({ file, fileList }) => {
    const updatedFileList = [...fileList];
    const index = updatedFileList.findIndex((item) => item.uid === file.uid);
    if (index !== -1) {
      updatedFileList[index] = { ...file, status: "done" };
      setFileList(updatedFileList);
    }
    if (file.status === "done") {
      updatedFileList[index] = { ...file, status: "done" };
      setFileList(updatedFileList);
    } else if (file.status === "error") {
      updatedFileList[index] = { ...file, status: "error" };
      setFileList(updatedFileList);
    } else if (file.status === "removed") {
      setFileList(updatedFileList.filter((item) => item.uid !== file.uid));
    }
  };
  const handleToggleModal = () => {
    setIsOpen(false);
    setModalSide("Log");
  };

  const handleChangeModalSide = (e) => {
    setModalSide(e);
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button">
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}>
        Upload
      </div>
    </button>
  );
  const ratingChanged = (newRating) => {
    setRating(newRating);
  };
  const onaction = async (dat) => {
    const formData = new FormData();
    formData.append("filename", dat.file.name);
    formData.append("foldername", "ProductReviewImages");
    formData.append("file", dat.file);
    await axios
      .post("https://api.bechofy.in/AddImage", formData, {
        headers: {
          Schema: "Kaawaii_Cosmetics",
        },
      })
      .then((res) => {
        setUrlData([...urlData, res.data.image]);
      })
      .catch((err) => {
        if (err.response) {
          console.log("Error Response:", err.response.data);
          console.log("Error Status:", err.response.status);
          console.log("Error Headers:", err.response.headers);
        } else if (err.request) {
          console.log("Error Request:", err.request);
        } else {
          console.log("Error Message:", err.message);
        }
        console.log("Error Config:", err.config);
      });
  };
  const onReviewClick = () => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    if (logindetails) {
      showDrawer();
    } else {
      setIsOpen(true);
    }
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    PostApiCall.postRequest(
      {
        // id: parseInt(params.variantid),
        route: params.productname,
        // customerid: null,
      },
      "GetProductByVariant"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          for (var i = 0; i < Object.keys(obj.data).length; i++) {
            if (obj.data[i].fld_route == params.productname) {
              setSoldBy(obj.data[i].fld_manufacturedBy);
              setSelectedVariant(obj.data[i]);
              setAvgRatings(obj.data[i].avg_rating);
              setMRP(obj.data[i].fld_mrp);
              setDiscountPrice(obj.data[i].fld_selling_price);
              setVariantId(obj.data[i].fld_variantid);
              setItemName(obj.data[i].fld_itemname);
              setVariantName(obj.data[i].fld_variantname);
              setCategoryName(obj.data[i].fld_categoryname);
              setVerticalName(obj.data[i].fld_verticlename);
              setVerticalId(obj.data[i].fld_verticleid);
              setCatId(obj.data[i].fld_categoryid);
              setInStock(obj.data[i].available_stock);
              setCountryOfOrigin(obj.data[i].fld_origin_country);
              setShortDescription(obj.data[i].fld_short_description);
              setItemRoute(obj.data[i].fld_route);
              setLongDescription(obj.data[i].fld_long_description);
              setTotalRatings(obj.data[i].total_ratings);
              setLoading(false);
              PostApiCall.postRequest(
                {
                  variantid: parseInt(obj.data[i].fld_categoryid),
                },
                "GetRelatedProduct"
              ).then((results) =>
                results.json().then((obj1) => {
                  if (results.status === 200 || results.status === 201) {
                    setRelatedProducts(obj1.data);
                  }
                })
              );
            }
          }
        }
      })
    );
    itemReviews();
  }, [params.variantid]);

  useEffect(() => {
    const InCart = cartItems.find((item) => {
      if (item.fld_variantid == variantId) {
        return item;
      }
      return false;
    });
    setIsInCart(InCart);
  }, [cartItems, variantId]);
  useEffect(() => {
    GetProductVertical();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const GetProductVertical = () => {
    PostApiCall.postRequest(
      {
        stateid: null,
      },
      "Get_All_Items"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setApiData(obj1.data);
        }
      })
    );
  };
  const writeTextInClipboard = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        Notiflix.Notify.success("Link copied to clipboard");
      })
      .catch((err) => { });
  };
  const itemReviews = () => {
    const logDetails = JSON.parse(localStorage.getItem("CustomerData"));
    if (logDetails && logDetails.length) {
      setCustomerId(logDetails[0].fld_customerid);
    }

    PostApiCall.postRequest(
      {
        whereClause: `where fld_variant_id=${variantId} and fld_status='active' order by fld_review_id desc`,
        recordCount: "Top 5 *",
      },
      "GetReviews"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setReviewData(obj1.data);
          // comment to be removed later
          // if (obj1.data.length != 0) {
          //   if (
          //     obj1.data.some(
          //       (customer) =>
          //         customer.fld_customer_id === logDetails[0].fld_customerid
          //     )
          //   ) {
          //     setIsDisabled(true);
          //   }
          // }
        }
      })
    );
  };

  // const crumPath = verticalName == "Perfume" ? '/ct/perfumes/1/1' : '/ct/bags/1/3'

  let crumbPath;
  switch (verticalName.trim()) {
    case 'Perfume':
      crumbPath = '/ct/perfume'
      break;
    case 'Bags':
      crumbPath = '/ct/bags'
      break;
    case 'Accessories':
      crumbPath = '/ct/accessories'
      break;
  }
  const crumbs = [
    { name: "Home", path: "/" },
    {
      name: `${verticalName} - ${categoryName}`,
      path: crumbPath,
    },
    { name: `${variantName}`, path: "" },
  ];


  const onPostReviewImages = (id, arr) => {
    PostApiCall.postRequest(
      {
        reviewid: id,
        reviewImage: arr,
      },
      "AddReviewImages"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Review Submitted");
          setOpen(false);
          itemReviews();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };

  const onPostReview = () => {
    Notiflix.Loading.dots("Please wait...");
    if (rating == null) {
      Notiflix.Notify.failure("Ratings cannot be left empty");
      Notiflix.Loading.remove();
      return;
    }
    if (reviewDescription == "") {
      Notiflix.Notify.failure("Review cannot be left empty");
      Notiflix.Loading.remove();
      return;
    }
    handlePostreview();
  };

  const handlePostreview = () => {
    let loginDetails = localStorage.getItem("CustomerData");
    let customerDetails = JSON.parse(loginDetails);
    PostApiCall.postRequest(
      {
        userId: customerDetails[0].fld_customerid,
        variantId: variantId,
        reviewid: null,
        rating: rating,
        headline: headline,
        reviewDescription: reviewDescription,
      },
      "AddProductReview"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          if (urlData.length) {
            onPostReviewImages(obj.data[0].review_id, urlData);
          }
          setOpen(false);

          Notiflix.Notify.success("Review Submitted");
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };

  return (
    <>
      <GetSeoData type="Variant" id={variantId} route={params.productname} />
      <LoginModal
        isOpen={isOpen}
        handleToggleModal={handleToggleModal}
        modalSide={modalSide}
        handleChangeModalSide={handleChangeModalSide}
      />
      {/* <section className="section-spacing-x section-spacing-x-mobile py-4"> */}
      <section className="section-spacing-x section-spacing-x-mobile pt-0 pb-0 px-0">
        <InnerBanner loadingState={loading} />
        <div className="container-fluid mt-2 mb-4 my-lg-5">
          <div className="row">
            {loading == true ? (
              <div className="container-fluid product-detail-skeleton">
                <div className="row pt-4">
                  <div className="col-md-5">
                    <div className="large-img product-detail-img">
                      <Skeleton.Image className="h-100 w-100" />
                    </div>
                    <div className="col-12 d-flex flex-wrap mb-4 mb-lg-0 mt-4">
                      <div className="thumbnail-img row w-100">
                        <Skeleton.Image />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <Skeleton active />
                    <div className="mt-5">
                      <Skeleton.Image />
                    </div>
                    <div className="buttons flex-column detailsbtn mb-0">
                      <h2 className="prod-price">
                        <Skeleton.Button />
                      </h2>
                    </div>
                    <div className="mt-3">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <Skeleton />
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="my-4">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-3">
                    <div className="product-img-skeleton">
                      <Skeleton.Image className="h-100 w-100" />
                      <div className="buttons flex-column detailsbtn mb-0">
                        <h2 className="prod-price">
                          <Skeleton.Button />
                        </h2>
                      </div>
                      <div className="mt-3">
                        <Skeleton.Input />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 d-none d-lg-block">
                    <div className="product-img-skeleton">
                      <Skeleton.Image className="h-100 w-100" />
                      <div className="buttons flex-column detailsbtn mb-0">
                        <h2 className="prod-price">
                          <Skeleton.Button />
                        </h2>
                      </div>
                      <div className="mt-3">
                        <Skeleton.Input />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 d-none d-lg-block">
                    <div className="product-img-skeleton">
                      <Skeleton.Image className="h-100 w-100" />
                      <div className="buttons flex-column detailsbtn mb-0">
                        <h2 className="prod-price">
                          <Skeleton.Button />
                        </h2>
                      </div>
                      <div className="mt-3">
                        <Skeleton.Input />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 d-none d-lg-block">
                    <div className="product-img-skeleton">
                      <Skeleton.Image className="h-100 w-100" />
                      <div className="buttons flex-column detailsbtn mb-0">
                        <h2 className="prod-price">
                          <Skeleton.Button />
                        </h2>
                      </div>
                      <div className="mt-3">
                        <Skeleton.Input />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6 col-md-12 col-lg-3">
                    <div className="product-img-skeleton">
                      <Skeleton.Image className="h-100 w-100" />
                      <div className="buttons flex-column detailsbtn mb-0">
                        <h2 className="prod-price">
                          <Skeleton.Button />
                        </h2>
                      </div>
                      <div className="mt-3">
                        <Skeleton.Input />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-12 col-lg-3">
                    <div className="product-img-skeleton">
                      <Skeleton.Image className="h-100 w-100" />
                      <div className="buttons flex-column detailsbtn mb-0">
                        <h2 className="prod-price">
                          <Skeleton.Button />
                        </h2>
                      </div>
                      <div className="mt-3">
                        <Skeleton.Input />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 d-none d-lg-block">
                    <div className="product-img-skeleton">
                      <Skeleton.Image className="h-100 w-100" />
                      <div className="buttons flex-column detailsbtn mb-0">
                        <h2 className="prod-price">
                          <Skeleton.Button />
                        </h2>
                      </div>
                      <div className="mt-3">
                        <Skeleton.Input />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 d-none d-lg-block">
                    <div className="product-img-skeleton">
                      <Skeleton.Image className="h-100 w-100" />
                      <div className="buttons flex-column detailsbtn mb-0">
                        <h2 className="prod-price">
                          <Skeleton.Button />
                        </h2>
                      </div>
                      <div className="mt-3">
                        <Skeleton.Input />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="col-lg-6">
                  <div className="d-block d-lg-none">
                    <Breadcrumbs crumbs={crumbs} />
                  </div>
                  <div className="row">
                    <div className="col-lg-3 order-2 order-lg-1">
                      <div className="row pt-1 pt-lg-0 mt-2 mt-lg-0">
                        {selectedVariant &&
                          selectedVariant.VariantImage &&
                          variantImage == null
                          ? selectedVariant.VariantImage.split("#").map(
                            (url, index) => (
                              <div className="col-2 col-lg-12 pe-0 mb-lg-2">
                                <img
                                  src={url}
                                  onClick={() => {
                                    setSelectedVariantImg(url);
                                  }}
                                  className="img-fluid product-detail-img"
                                  alt={variantName}
                                />
                              </div>
                            )
                          )
                          : variantImage != null
                            ? variantImage.split("$").map((url, index) => <></>)
                            : ""}
                      </div>
                    </div>
                    <div className="col-lg-9 order-1 order-lg-2 ps-lg-2">
                      <img
                        src={
                          // eslint-disable-next-line eqeqeq
                          selectedVariantImg == undefined
                            ? variantImage == null
                              ? selectedVariant.VariantImage
                              : variantImage.split("$")[0]
                            : selectedVariantImg
                        }
                        className="img-fluid w-100 product-detail-img"
                        alt={variantName}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 product-deatail-content">
                  <div className="d-none d-lg-block">
                    <Breadcrumbs crumbs={crumbs} />
                  </div>
                  <h1 className="mb-0 fs-4 fw-bold mt-3 mt-lg-0">
                    {variantName}
                  </h1>
                  {/* <div className="d-flex ">
                    <ReactStars
                      value={avgRatings}
                      count={5}
                      edit={false}
                      disabled
                      size={24}
                      color2={"#ffd700"}
                    />
                    <p className="mb-0 d-flex align-items-center ms-1 gap-1">
                      <b>{totalRatings}</b> customer review
                      {totalRatings !== 1 ? "s" : ""}
                    </p>
                  </div> */}
                  <div className="price border-bottom mb-2 mt-1">
                    <h3 className="fs-5">
                      ₹{" "}
                      {isNaN(parseFloat(discountPrice).toFixed(0))
                        ? ""
                        : parseFloat(discountPrice).toFixed(0)}
                      <p>(Inclusive of all taxes)</p>
                      <del
                        style={{
                          display: discountPrice != mrp ? "block" : "none",
                        }}>
                        ₹{" "}
                        {isNaN(parseFloat(mrp).toFixed(0))
                          ? "0"
                          : parseFloat(mrp).toFixed(0)}
                      </del>
                    </h3>
                  </div>
                  <div className="view mb-2">
                    <FiEye /> <b>{number}</b> people are viewing this right now
                  </div>
                  <p className="mb-3">
                    {shortDescription &&
                      Parser(
                        ("" + shortDescription + "")
                          .replace(/font-family/g, "")
                          .replace(/<br\/?>/gi, " ")
                      )}
                  </p>
                  {selectedVariant.variant_list !== null ? (
                    <div className="row variants">
                      <p className="mb-2 mt-0">Available in</p>
                      {selectedVariant.variant_list === "" ||
                        selectedVariant.variant_list == null
                        ? ""
                        : selectedVariant.variant_list
                          .split("#")
                          .map((carddata, index) => (
                            <div
                              className="col-3 col-lg-2"
                              onClick={() => {
                                history.push(
                                  `${process.env.PUBLIC_URL
                                  }/p/${carddata.fld_route?.replace(
                                    /\s/g,
                                    "-"
                                  ).replace('/', '')}`
                                );
                                // getCartdetails();
                                // setCheckedDiv(
                                //   carddata
                                //     .split("#")[0]
                                //     .split("$")[1]
                                //     .split(",")[1]
                                // );
                                // setVariantList(carddata);
                                setMRP(
                                  carddata
                                    .split("#")[0]
                                    .split("$")[1]
                                    .split(",")[2]
                                );
                                setDiscountPrice(
                                  carddata
                                    .split("#")[0]
                                    .split("$")[1]
                                    .split(",")[3]
                                );
                                // setSavePer(
                                //   carddata
                                //     .split("#")[0]
                                //     .split("$")[1]
                                //     .split(",")[4]
                                // );
                                // setYouSave(
                                //   carddata
                                //     .split("#")[0]
                                //     .split("$")[1]
                                //     .split(",")[5]
                                // );
                                // setStock(
                                //   carddata
                                //     .split("#")[0]
                                //     .split("$")[1]
                                //     .split(",")[6]
                                // );
                                setVariantId(
                                  carddata
                                    .split("#")[0]
                                    .split("$")[1]
                                    .split(",")[1]
                                );
                                setVariantName(
                                  carddata
                                    .split("#")[0]
                                    .split("$")[1]
                                    .split(",")[0]
                                );
                                // setWeight(
                                //   carddata.split("$")[0].split(",")[0]
                                // );
                                // setCategoryName(
                                //   carddata
                                //     .split("#")[0]
                                //     .split("$")[1]
                                //     .split(",")[8]
                                // );
                                setVariantImage(
                                  carddata.split("#")[0].split(",")[7]
                                );
                              }}>
                              <img
                                src={
                                  carddata
                                    .split("#")[0]
                                    .split("$")[1]
                                    .split(",")[7]
                                    .split("~")[0]
                                }
                                className="img-fluid"
                                alt=""
                              />
                              <p>
                                {
                                  carddata
                                    .split("#")[0]
                                    .split("$")[1]
                                    .split(",")[0]
                                }
                              </p>
                            </div>
                          ))}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <div className="select-color mb-3">
                <p className="mb-2 mt-0">Color: Green</p>
                <div className="d-flex">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div className="select-size mb-3">
                <p className="mb-2 mt-0">Size: L</p>
                <div className="d-flex">
                  <span>L</span>
                  <span>M</span>
                  <span>S</span>
                </div>
              </div> */}
                  {/* <div className="add-btn-box border-bottom pb-3 mb-3">
                <div className="quantity border d-flex align-items-center p-2 me-4">
                  <span>
                    <FiPlus />
                  </span>
                  <input type="text" />
                  <span>
                    <FiMinus />
                  </span>
                </div> */}
                  {/* {
                    inStock === "In Stock" ? */}
                  <div className="border-bottom pb-3 mb-3">
                    {storeStatus == "Active" && (
                      <div className="add-btn-box">
                        {isInCart ? (
                          <AddToCartQty
                            itemid={variantId}
                            quantity={isInCart.fld_quantity}
                            cartlineid={isInCart.fld_cart_dtl_id}
                          />
                        ) : (
                          <span className=" cursor-pointer">
                            <AddToCart
                              itemid={variantId}
                              classNames={`btn ${productsbtncolor}  default-btn-one default-btn-bags cursor-pointer mx-0`}
                            />
                          </span>
                        )}
                      </div>
                    )}
                    <p className="mb-0 pt-3">(Taxes and shipping calculated at checkout.)</p>
                  </div>
                  {/* :
                      <div >
                        <div className="sold-out-btn">Sold Out
                        </div>
                      </div>
                  } */}
                  <p className="mb-0">
                    <b>Sold by</b>: {soldBy}
                  </p>
                  <p className="mb-0">
                    <b>Country of Origin</b>: {countryOfOrigin}
                  </p>
                  <div className="d-flex mt-2 gap-1">
                    <p className="mb-0">
                      <b>Share</b>:
                    </p>
                    <ul className="d-flex mb-0 gap-3">
                      <li>
                        <FacebookShareButton
                          url={`https://kaawaiicosmetics.com${process.env.PUBLIC_URL
                            }/p/${itemRoute?.replace(
                              /\s/g,
                              "-"
                            ).replace('/', '')}`}
                          quote={variantName}>
                          <FaFacebookF />
                        </FacebookShareButton>
                      </li>
                      <li>
                        <WhatsappShareButton
                          url={`https://kaawaiicosmetics.com${process.env.PUBLIC_URL
                            }/p/${itemRoute?.replace(
                              /\s/g,
                              "-"
                            ).replace('/', '')}`}
                          quote={variantName}>
                          <FaWhatsapp />
                        </WhatsappShareButton>
                      </li>
                      {/* <li>
                        <LinkedinShareButton
                          url={`https://kaawaiicosmetics.com/productdetail/${itemName?.replace(
                            /\s/g,
                            "-"
                          )}-${variantName?.replace(/\s/g, "-")}/${variantId}`}
                          quote={variantName}>
                          <FaLinkedinIn />
                        </LinkedinShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          url={`https://kaawaiicosmetics.com/productdetail/${itemName?.replace(
                            /\s/g,
                            "-"
                          )}-${variantName?.replace(/\s/g, "-")}/${variantId}`}
                          quote={variantName}>
                          <BsTwitterX />
                        </TwitterShareButton>
                      </li> */}
                      <li
                        onClick={() =>
                          writeTextInClipboard(
                            `https://kaawaiicosmetics.com${process.env.PUBLIC_URL
                            }/p/${itemRoute?.replace(
                              /\s/g,
                              "-"
                            ).replace('/', '')}`
                          )
                        }>
                        <button className="border-0 bg-transparent text-dark">
                          <FaRegCopy />
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="col-12 mt-4 product-detail-tabs"> */}
                {/* <Tabs defaultActiveKey="1"> */}
                {/* <Tabs.TabPane tab="Description" key="1">
                  <p>
                    {longDescription &&
                      Parser(
                        ("" + longDescription + "")
                          .replace(/font-family/g, "")
                          .replace(/<br\/?>/gi, " ")
                      )}
                  </p>
                </Tabs.TabPane>
                 <Tabs.TabPane tab="Additional Information" key="2">
                  <table class="woocommerce-product-attributes shop_attributes">
                    <tbody>
                      <tr>
                        <th>Color</th>
                        <td>
                          <p>Blue, Green, Yellow</p>
                        </td>
                      </tr>
                      <tr>
                        <th>Size</th>
                        <td>
                          <p>L, M, S, XL</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Tabs.TabPane> */}
                {/* <Tabs.TabPane
                      tab={`Reviews (${reviewData.length})`}
                      key="3"
                    >
                      <div>
                        <div className="d-flex justify-content-between mb-3 align-items-end">
                          <h3 className="mb-0">
                            {reviewData.length} review
                            {reviewData.length !== 1 ? "s" : ""} for{" "}
                            {variantName}
                          </h3>
                          <button
                            className="theme-btn border-0"
                            onClick={onReviewClick}
                          >
                            Write a review
                          </button>
                        </div>
                        {reviewData.length ? (
                          reviewData.map((review, index) => {
                            return (
                              <div
                                key={index}
                                className="product-review-card mb-4"
                              >
                                <div className="details">
                                  <div>
                                    <img
                                      src={MaleAvatar}
                                      alt="review-avatar"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div>
                                    <ReactStars
                                      value={review.fld_rating}
                                      count={5}
                                      edit={false}
                                      disabled
                                      size={24}
                                      color2={"#ffd700"}
                                    />
                                    <p>
                                      <b>{review.fld_name}</b>
                                    </p>
                                    <p>{}</p>
                                    <small>
                                      {moment(review.fld_review_date).format(
                                        "D-MMM-YYYY"
                                      )}
                                    </small>
                                  </div>
                                </div>
                                <p>{review.fld_review_description}</p>
                                <div className="row mt-2">
                                  {review.review_images
                                    ? review.review_images
                                        .split(",")
                                        .map((reviewImg) => {
                                          return (
                                            <div className="col-6 col-lg-2">
                                              <img
                                                src={reviewImg}
                                                alt=""
                                                className="img-fluid"
                                              />
                                            </div>
                                          );
                                        })
                                    : ""}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="text-center">
                            <img src={reviewIcon} className="img-fluid" />
                            <p className="my-4 text-center fs-5">
                              No Reviews Found
                            </p>
                          </div>
                        )}
                        {reviewData.length ? (
                          <div className="mt-3 text-center">
                            <button className="theme-btn border-0">
                              View All
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Tabs.TabPane> */}
                {/* </Tabs> */}
                {/* </div> */}
                {/* <div className="col-12 mt-lg-1">
                  <h2 className="main-heading text-center">Related Products</h2>
                  {relatedProducts?.length ? (
                    <Swiper
                      slidesPerView={2}
                      spaceBetween={10}
                      pagination={{
                        clickable: true,
                        dynamicBullets: true,
                      }}
                      breakpoints={{
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 20,
                        },
                      }}
                      modules={[Pagination]}
                      className="mySwiper pb-4 pb-lg-5"
                    >
                      {relatedProducts.map((item) => {
                        const isCart = cartItems.find((val) => {
                          if (item.fld_variantid === val.fld_variantid) {
                            return val;
                          }
                          return false;
                        });
                        return (
                          <SwiperSlide>
                            <RelatedProducts
                              itemdata={item}
                              key={item.fld_variantid}
                              isInCart={isCart}
                            />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  ) : (
                    <p className="text-center my-4">No Products Found</p>
                  )}
                </div> */}
              </>
            )}
          </div>
          {/* <div className="row mt-4">
            <h2 className="main-heading text-center">
              Recently Viewed Products
            </h2>
            {apiData.length > 0 ? (
              apiData.slice(10, 14).map((val) => {
                return (
                  <div
                    key={val.fld_variantid}
                    className="col-6 col-lg-3 mb-4 mb-lg-0"
                  >
                    <ProductCard productData={val} />
                  </div>
                );
              })
            ) : (
              <div className="d-flex justify-content-center align-items-center w-100 listingpage-height blank-section">
                Not available
              </div>
            )}
          </div> */}
        </div>
      </section>
      <Drawer
        Drawer
        title="Add a review"
        onClose={onClose}
        open={open}
        width={500}
        className="write-review-drawer">
        <div>
          <div className="ratings d-flex align-items-center">
            <p className="mb-0 me-2 fw-bold mb-0">Your rating:</p>
            {/* <ul className="d-flex gap-1 p-0">
              {[...Array(5)].map((star, index) => {
                const ratingValue = index + 1;
                return (
                  <label className="star-review">
                    <input
                      type="radio"
                      value={rating}
                      onClick={() => {
                        setRating(ratingValue);
                      }}
                    />
                    <FaRegStar
                      size={35}
                      className="star"
                      color={
                        ratingValue <= (hover || rating) ? "#FFC107" : "#e4e5e9"
                      }
                      onMouseEnter={() => {
                        setHover(ratingValue);
                      }}
                      onMouseLeave={() => {
                        setHover(null);
                      }}
                    />
                  </label>
                );
              })}
            </ul> */}
            <ReactStars
              value={rating}
              count={5}
              edit={true}
              disabled
              size={24}
              onChange={ratingChanged}
              color2={"#ffd700"}
            />
          </div>
          <div
            style={{
              display: rating != null ? "block" : "none",
            }}
            className="container-clear"
            onClick={() => {
              setRating(null);
            }}>
            clear
          </div>
          <TextArea
            rows={8}
            placeholder="Your Reviews *"
            className="my-4"
            value={reviewDescription}
            onChange={(e) => setReviewDescription(e.target.value)}
          />
          <div>
            <Upload
              customRequest={onaction}
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}>
              {/* at most 8 images can be selected  */}
              {fileList.length >= 5 ? null : uploadButton}
            </Upload>
            {previewImage && (
              <Image
                wrapperStyle={{
                  display: "none",
                }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(""),
                }}
                src={previewImage}
              />
            )}
          </div>
          <button className="theme-btn border-0 mt-4" onClick={onPostReview}>
            Submit
          </button>
        </div>
      </Drawer>
    </>
  );
}
