import React from "react";
// import Homebannerimg from "../../assets/img/home-banner/home-banner.jpg";
// import HomeMobileBanner from "../../assets/img/home-banner/home-mobile.jpg";
import Homebannerimg from "../../assets/img/home-banner/kaawaii-home-banner.jpg";
import HomeMobileBanner from "../../assets/img/home-banner/kaawaii-MOBILE-banner.jpg";
import "../MainNavabar/MainNavabar.css";
import "./HomeBanner.css";

import { Skeleton } from "antd";
export default function HomeBanner({ banners, loadingState }) {

  return (
    <>
      {
        loadingState === true ?
          <div className="contanier-fluid homebannersize homebannersize-skeleton p-0 m-0">
            <Skeleton.Image className="w-100 d-none d-lg-block" />

            <div className="mobile-imgae-space mobile-imgae-space-skeleton position-relative">
              <Skeleton.Image
                className="img-fluid d-block d-lg-none position-static h-100"
              />
            </div>
          </div> :
          <div className="contanier-fluid homebannersize p-0 m-0">
            <img src={Homebannerimg} className="w-100 d-none d-lg-block" alt="Kaawaii Cosmetics" />

            <div className="mobile-imgae-space position-relative">
              <img
                src={HomeMobileBanner}
                className="img-fluid d-block d-lg-none position-static"
                alt="Kaawaii Cosmetics"
              />
            </div>
            {/* <img src={Homebannerimg} className="w-100" /> */}
          </div>}
    </>
  );
}
